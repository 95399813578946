body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.homeStyles_image__390Ug {
  /* Image styles */
}

.homeStyles_title__1qegz {
  /* Title styles */
}

.homeStyles_subtitle__M065n {
  /* Subtitle styles */
}

.homeStyles_spacing__3YZJb {
  /* Spacing styles */
}
.homeStyles_cardGridItem__2Cp2q {
  display: flex;
}

.homeStyles_cardWrapper__1f0Mb {
  width: 100%;
  height: auto;
}

.homeStyles_cardImage__2YveX {
  margin-top: 1rem;
  width: 75% !important;
  height: 75%!important;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;

}

@font-face {
  font-family: 'ExpressSansCondMedium'; /* You can choose any name you like */
  src: url("/static/media/print-2147-ecc-bagstuffer-3.5x6_Document fonts_EXPRESSSANS-CONDMEDIUM.bc0b3e65.OTF") format('opentype');
  /* Add additional src declarations for other font formats if necessary */
  font-family: 'ExpressSansCondRegular'; 
  src: url("/static/media/print-2147-ecc-bagstuffer-3.5x6_Document fonts_EXPRESSSANS-CONDREGULAR.fb568fe9.OTF") format('opentype');
  font-family: 'ExpressSansMedium'; 
  src: url("/static/media/print-2147-ecc-bagstuffer-3.5x6_Document fonts_EXPRESSSANS-MEDIUM.11361c4d.OTF") format('opentype');
  font-family: 'ExpressSansRegular'; 
  src: url("/static/media/print-2147-ecc-bagstuffer-3.5x6_Document fonts_EXPRESSSANS-REGULAR.38de7d5f.OTF") format('opentype');
}

.homeStyles_cardTitle__c_Ean {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Arial,Helvetica Neue,helvetica,sans-serif !important;
  font-size: 1.3rem !important;
  /* margin-left: auto;
  margin-right: auto; */
  
}

.homeStyles_cardContent__aW0yw {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding-top: 0 !important;
  margin-top: 1rem;
  /* padding-bottom: 1rem !important; */
  

}
.homeStyles_cardheroContent__2AajY {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 5rem;
}
.homeStyles_cardheroTitle__2eo9R {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Arial,Helvetica Neue,helvetica,sans-serif !important;
  font-size: 1.3rem !important;
  font-weight: 600 !important;

  /* margin-left: auto;
  margin-right: auto; */
  

}
.homeStyles_pageLogo__HrBs6{
  width:35%
}
.homeStyles_heroimagediv__3jYA8{
  padding-bottom: 1rem;
  padding-top:1rem;
}
.homeStyles_PageTitle__2E0xi{

  word-wrap: break-word;

  color:#000000DE;
  /* padding-top:2rem; */
  /* margin: bottom 2rem; */
  font-size: 1.5rem !important; 
  font-family: Arial,Helvetica Neue,helvetica,sans-serif !important;

}

.homeStyles_boldListItem__3TdlZ {
  font-weight: bold;
  margin-bottom: 1rem;
}
.homeStyles_subList__2uhKc {
  margin-left: 20px;
  margin-top: 10px;;
  font-weight: normal;

}

body {
  background-color: #F3F3F3;

}

@media (max-width: 600px) {
  .homeStyles_cardWrapper__1f0Mb {
    width: 70%; /* Adjust the width to make the card occupy the full width of the screen */
    margin-left: auto; /* Center the image horizontally */
    margin-right: auto;
  }

  .homeStyles_cardImage__2YveX {
    width: 70%; /* Adjust the width of the image to occupy 70% of the card */
    margin: 10px auto; /* Center the image horizontally */

  }

  .homeStyles_cardContent__aW0yw {
    padding: 8px; /* Add some padding to the content area to give more space */
  }

  .homeStyles_cardTitle__c_Ean {
    font-size: 16px; /* Reduce the font size of the title for better readability */
  }
  .homeStyles_pageLogo__HrBs6{
    width:55%
  }
  .homeStyles_PageTitle__2E0xi {
    font-size: 1.2rem !important; /* Reduce the font size for mobile devices */
    padding-top: 1rem; /* Reduce the top padding for mobile devices */
    margin-bottom: 1rem; /* Reduce the bottom margin for mobile devices */
  }
}
