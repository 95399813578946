.image {
  /* Image styles */
}

.title {
  /* Title styles */
}

.subtitle {
  /* Subtitle styles */
}

.spacing {
  /* Spacing styles */
}
.cardGridItem {
  display: flex;
}

.cardWrapper {
  width: 100%;
  height: auto;
}

.cardImage {
  margin-top: 1rem;
  width: 75% !important;
  height: 75%!important;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;

}

@font-face {
  font-family: 'ExpressSansCondMedium'; /* You can choose any name you like */
  src: url('../fonts/print-2147-ecc-bagstuffer-3.5x6_Document\ fonts_EXPRESSSANS-CONDMEDIUM.OTF') format('opentype');
  /* Add additional src declarations for other font formats if necessary */
  font-family: 'ExpressSansCondRegular'; 
  src: url('../fonts/print-2147-ecc-bagstuffer-3.5x6_Document\ fonts_EXPRESSSANS-CONDREGULAR.OTF') format('opentype');
  font-family: 'ExpressSansMedium'; 
  src: url('../fonts/print-2147-ecc-bagstuffer-3.5x6_Document\ fonts_EXPRESSSANS-MEDIUM.OTF') format('opentype');
  font-family: 'ExpressSansRegular'; 
  src: url('../fonts/print-2147-ecc-bagstuffer-3.5x6_Document\ fonts_EXPRESSSANS-REGULAR.OTF') format('opentype');
}

.cardTitle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Arial,Helvetica Neue,helvetica,sans-serif !important;
  font-size: 1.3rem !important;
  /* margin-left: auto;
  margin-right: auto; */
  
}

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding-top: 0 !important;
  margin-top: 1rem;
  /* padding-bottom: 1rem !important; */
  

}
.cardheroContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 5rem;
}
.cardheroTitle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Arial,Helvetica Neue,helvetica,sans-serif !important;
  font-size: 1.3rem !important;
  font-weight: 600 !important;

  /* margin-left: auto;
  margin-right: auto; */
  

}
.pageLogo{
  width:35%
}
.heroimagediv{
  padding-bottom: 1rem;
  padding-top:1rem;
}
.PageTitle{

  word-wrap: break-word;

  color:#000000DE;
  /* padding-top:2rem; */
  /* margin: bottom 2rem; */
  font-size: 1.5rem !important; 
  font-family: Arial,Helvetica Neue,helvetica,sans-serif !important;

}

.boldListItem {
  font-weight: bold;
  margin-bottom: 1rem;
}
.subList {
  margin-left: 20px;
  margin-top: 10px;;
  font-weight: normal;

}

body {
  background-color: #F3F3F3;

}

@media (max-width: 600px) {
  .cardWrapper {
    width: 70%; /* Adjust the width to make the card occupy the full width of the screen */
    margin-left: auto; /* Center the image horizontally */
    margin-right: auto;
  }

  .cardImage {
    width: 70%; /* Adjust the width of the image to occupy 70% of the card */
    margin: 10px auto; /* Center the image horizontally */

  }

  .cardContent {
    padding: 8px; /* Add some padding to the content area to give more space */
  }

  .cardTitle {
    font-size: 16px; /* Reduce the font size of the title for better readability */
  }
  .pageLogo{
    width:55%
  }
  .PageTitle {
    font-size: 1.2rem !important; /* Reduce the font size for mobile devices */
    padding-top: 1rem; /* Reduce the top padding for mobile devices */
    margin-bottom: 1rem; /* Reduce the bottom margin for mobile devices */
  }
}